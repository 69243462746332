import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useParsed,
  useNavigation,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  MarkdownField,
  DateField,
  CreateButton,
} from "@refinedev/antd";
import { Table, Space, Tag } from "antd";
import dayjs from "dayjs";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import SelfRegisterButton from "components/SelfRegisterButton";

const ORG_ID = localStorage.getItem("org_id");

export const PartnerList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useParsed();
  const { push } = useNavigation();

  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable({
    resource: `${ORG_ID}/business_partners`,
    filters: {
      permanent: [
        {
          field: "type",
          operator: "eq",
          value: resource?.name,
        },
      ],
    },
  });

  const types = [
    {
      label: "โรงแรม",
      value: "Hotel",
    },
    {
      label: "ร้านอาหาร",
      value: "Restaurant",
    },
    {
      label: "ทัวร์",
      value: "TourActivity",
    },
    {
      label: "ร้านค้า",
      value: "Shop",
    },
  ];

  const status = [
    {
      label: "เจ้าหน้าที่กำลังตรวจสอบ",
      value: "pending",
      type: "processing",
      icon: <SyncOutlined spin />,
    },
    {
      label: "ไม่ตอบรับ",
      value: "cancelled",
      type: "error",
      icon: <CloseCircleOutlined />,
    },
    {
      label: "แบน",
      value: "banned",
      type: "error",
      icon: <MinusCircleOutlined />,
    },
    {
      label: "ตอบรับ",
      value: "approved",
      type: "success",
      icon: <CheckCircleOutlined />,
    },
  ];

  return (
    <List
      canCreate={false}
      headerButtons={
        <>
          <SelfRegisterButton to="https://partners.admin.aowtani.com/register" />
        </>
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="name" title="ชื่อธุรกิจ" />
        <Table.Column
          dataIndex="type"
          title="ประเภท"
          render={(value, record: any) => {
            const f = types.find((i) => i.value === value);
            return f ? f.label : record?.type_details;
          }}
        />
        {/* <Table.Column dataIndex={["community", "name"]} title="ชุมชน" /> */}
        <Table.Column
          dataIndex="status"
          title="สถานะ"
          render={(value) => {
            const find = status.find((i) => i.value === value);
            return (
              <Tag icon={find?.icon} color={find?.type}>
                {find?.label}
              </Tag>
            );
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title="วันที่ลงทะเบียน"
          render={(text) => <DateField value={text} format="D/MM/YYYY" />}
        />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          align="center"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                resource="business_partners"
                hideText
                size="small"
                recordItemId={record.id}
                onSuccess={() => {
                  refetch();
                }}
              />
            </Space>
          )}
        />
        {(resource?.name === "TourActivity" || resource?.name === "Shop") && (
          <>
            <Table.Column
              title={
                resource.name === "TourActivity" ? "ดูแพ็คเกจ" : "ดูสินค้า"
              }
              dataIndex="actions"
              align="center"
              render={(_, record: BaseRecord) => (
                <Space>
                  <ShowButton
                    hideText
                    size="small"
                    onClick={() =>
                      push(
                        `${
                          resource.name === "TourActivity"
                            ? `packages`
                            : `products`
                        }/${record.id}`
                      )
                    }
                  />
                </Space>
              )}
            />
            <Table.Column
              title={
                resource.name === "TourActivity" ? "ดูการจอง" : "ดูการสั่งซื้อ"
              }
              dataIndex="actions"
              align="center"
              render={(_, record: BaseRecord) => (
                <ShowButton
                  hideText
                  size="small"
                  onClick={() =>
                    push(
                      `${
                        resource.name === "TourActivity" ? `booking` : `orders`
                      }/${record.id}`
                    )
                  }
                />
              )}
            />
          </>
        )}
      </Table>
    </List>
  );
};
