import { useList, useNavigation } from "@refinedev/core";
import { Card, Statistic } from "antd";
import { pluralize } from "utils";

type Type =
  | "destination_visit"
  | "community"
  | "local_guide"
  | "business_partner";

const ORG_ID = localStorage.getItem("org_id");

export const DashboardTotalCountCard: React.FC<{
  resource: Type;
  label: string;
}> = ({ resource, label }) => {
  const { push } = useNavigation();

  const { data: count } = useList({
    resource: `/organizations/${ORG_ID}/organization_analytics/counter`,
    queryOptions: {
      select: (data: any) => data.data[`${resource}_count`],
    },
  });

  return (
    <Card
      bordered={false}
      onClick={() =>
        push(
          resource === "business_partner"
            ? `${pluralize(resource)}/tour`
            : pluralize(resource)
        )
      }
      style={{ cursor: "pointer" }}
    >
      <Statistic
        title={label}
        value={(count as number | undefined) ?? 0}
        valueStyle={{ color: "#56ABD4", fontSize: 64, fontWeight: "bold" }}
      />
    </Card>
  );
};
