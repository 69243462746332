import React from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import UploadOne from "components/UploadOne";
import axiosInstance, { API_URL } from "config/axios";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();
  const { mutate } = useUpdate();

  const Data = queryResult?.data?.data;

  const handleSubmit = async (values: any) => {
    const payload = {
      user: {
        ...values,
      },
    };

    mutate({
      resource: "users",
      values: payload,
      id: values.id,
    });
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleSubmit}>
        <div style={{ width: 100 }}>
          <UploadOne name={["profile"]} value={Data?.profile} label="รูปภาพ" />
        </div>
        <Form.Item
          label="ไอดี"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
          hidden
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="ชื่อ-สกุล"
          name={["fullname"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ตำแหน่งงาน"
          name={["role"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="อีเมล์"
          name={["email"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เบอร์โทรศัพท์"
          name={["phone"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสเข้าใช้งาน"
          name={["username"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสผ่าน"
          name={["password"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Edit>
  );
};
