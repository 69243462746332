import { AuthBindings } from "@refinedev/core";
import axiosInstance, { API_URL } from "config/axios";
import Cookies from "js-cookie";
// import * as cookie from "cookie";
import { isExpired, decodeToken } from "react-jwt";

export const TOKEN_KEY = "refine-auth";

interface IDecode {
  user_id: number;
  exp: number;
}

export const authProvider: AuthBindings = {
  login: async ({ username, password }) => {
    const response = await axiosInstance.post("/login", {
      username,
      password,
    });

    if (!response.data.error) {
      sessionStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem("user_id", response.data.id);
      localStorage.setItem(
        "identity",
        JSON.stringify({
          fullname: response.data.fullname,
          profile: response.data.profile?.asset,
        })
      );

      if (!response.data.permission) {
        localStorage.setItem("org_id", "null");
        window.location.replace(`/onboarding`);
        console.log("not permission");
      } else {
        console.log("permissible");
        localStorage.setItem("org_id", response.data.permission.app_able_id);
        window.location.replace("/");
      }

      return {
        success: true,
        redirectTo: "/",
      };
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid username or password",
      },
    };
  },
  register: async (payload) => {
    const response = await axiosInstance.post("/sign_up", {
      user: {
        ...payload,
      },
    });
    if (!response.data.error) {
      sessionStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem("user_id", response.data.id);
      localStorage.setItem("org_id", "null");
      window.location.replace("/onboarding");
      return {
        success: true,
        redirectTo: "/onboarding",
      };
    }

    return {
      success: false,
      error: {
        message: "Invalid credentials",
        name: "Invalid credentials",
      },
    };
  },
  logout: async () => {
    localStorage.clear();
    sessionStorage.clear();
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token: any = sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;

      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    const identity = JSON.parse(localStorage.getItem("identity") || "{}");
    if (token) {
      return {
        id: 1,
        name: identity?.fullname,
        avatar: identity?.profile,
      };
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
