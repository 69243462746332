import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="รหัส"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" placeholder="กรอกรหัสที่ได้รับจากสมาชิก" />
        </Form.Item>
      </Form>
    </Create>
  );
};
