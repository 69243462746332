import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Row, Col, Space, Typography } from "antd";
import dayjs from "dayjs";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";
import { _transform } from "utils";

interface IDestinationType {
  id: number;
  name: string;
}

const { TextArea } = Input;

export const DestinationToVisitCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const { selectProps } = useSelect<IDestinationType>({
    resource: "destination_types",
    optionLabel: "name",
    optionValue: "id",
  });

  const handleOnFinish = async (values: any) => {
    const payload = {
      destination_visit: {
        ...values,
      },
    };
    onFinish(payload);
  };

  return (
    <Create saveButtonProps={saveButtonProps} title="สร้างสถานที่ท่องเที่ยว">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="ชื่อสถานที่"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ประเภทสถานที่"
          name={["destination_type_id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="เลือกประเภทสถานที่" {...selectProps} />
        </Form.Item>
        <Form.Item
          label="คำบรรยายสั้นๆ"
          name={["description"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="ที่อยู่"
          name={["address"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={12}>
          <Col md={12}>
            <Form.Item
              label={
                <Space>
                  ละติจูด
                  <Typography.Link
                    target="_blank"
                    href="https://drive.google.com/file/d/1TmkDU_0kIA-0iQqMUb1iv3MhwmcoV-DZ/view"
                  >
                    ดูวิธีการ
                  </Typography.Link>
                </Space>
              }
              name={["lat"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <Space>
                  ลองติจูด
                  <Typography.Link
                    target="_blank"
                    href="https://drive.google.com/file/d/1TmkDU_0kIA-0iQqMUb1iv3MhwmcoV-DZ/view"
                  >
                    ดูวิธีการ
                  </Typography.Link>
                </Space>
              }
              name={["long"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={
            <Space>
              Embed a map
              <Typography.Link
                target="_blank"
                href="https://drive.google.com/file/d/1pTh9pn4q5qxPWD5Ko72U-0HtMf3UgIYa/view"
              >
                ดูวิธีการ
              </Typography.Link>
            </Space>
          }
          name={["embed_map"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea rows={8} />
        </Form.Item>
        <UploadMultiple label="รูปภาพ" name={["banners"]} />
        <Form.Item
          label="รายละเอียด"
          name={["content"]}
          rules={[{ required: false, message: "Please enter the body" }]}
        >
          {/* @ts-ignore */}
          <TextEditor />
        </Form.Item>
      </Form>
    </Create>
  );
};
