import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";
import TextEditor from "components/TextEditor";
import UploadMultiple from "components/UploadMultiple";

export const CommunityEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const communitydata = queryResult?.data?.data;
  const handleOnFinish = async (values: any) => {
    const payload = {
      community: {
        ...values,
      },
    };
    onFinish(payload);
  };

  return (
    <Edit saveButtonProps={saveButtonProps} title="แก้ไขชุมชน">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="ชื่อชุมชน"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ที่อยู่"
          name={["address"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="คำอธิบายสั้นๆ"
          name={["detail"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <UploadMultiple
          label="รูปภาพ"
          name={["images"]}
          value={communitydata?.images}
        />
        <Form.Item
          label="รายละเอียด"
          name={["content"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          {/* @ts-ignore */}
          <TextEditor />
        </Form.Item>
      </Form>
    </Edit>
  );
};
