import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
  ImageField,
} from "@refinedev/antd";
import { Space, Typography } from "antd";
import { API_URL } from "config/axios";
import ImageList from "components/ImageList";

const { Title } = Typography;

export const CommunityShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading} title={`ข้อมูล${record?.name}`}>
      <Title level={5}>ชื่อชุมชน</Title>
      <TextField value={record?.name} />
      <Title level={5}>ที่อยู่ชุมชน</Title>
      <TextField value={record?.address} />
      <Title level={5}>คำอธิบายสั้นๆ</Title>
      <TextField value={record?.detail} />
      <Title level={5}>รูปภาพ</Title>
      <ImageList images={record?.images} />
      <Title level={5}>รายละเอียดชุมชน</Title>
      <div dangerouslySetInnerHTML={{ __html: record?.content }} />
    </Show>
  );
};
