import React, { useState } from "react";
import { Card, Table } from "antd";
import { SelectDate } from "../SelectDate";
import { useList } from "@refinedev/core";

const ORG_ID = localStorage.getItem("org_id");

interface ProductProps {
  id: string | number;
  name: string;
  count: number;
}

export const Product: React.FC = () => {
  const [dateString, setDateString] = useState("");

  const { data } = useList<ProductProps[]>({
    resource: `/organizations/${ORG_ID}/organization_analytics/top_products?${dateString}`,
    queryOptions: {
      select: (data: any) => {
        return data.data;
      },
    },
  });

  if (data === undefined) {
    return <div>loading...</div>;
  }

  return (
    <>
      <SelectDate onChange={setDateString} />
      <Table bordered={false} dataSource={data as any} pagination={false}>
        <Table.Column
          dataIndex="name"
          title="รายการสินค้า"
          render={(_, data: ProductProps, index) =>
            `${index + 1}. ${data.name}`
          }
        />
        <Table.Column dataIndex="count" title="จำนวน (ครั้ง)" />
      </Table>
    </>
  );
};
