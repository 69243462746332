import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  MarkdownField,
  DateField,
  CreateButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";
import dayjs from "dayjs";

export const CommunityList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable();

  return (
    <List headerButtons={<CreateButton meta={{}} />}>
      <Table {...tableProps} rowKey="รหัสส">
        <Table.Column dataIndex="id" title="ลำดับ" />
        <Table.Column dataIndex="name" title="ชุมชน" />
        <Table.Column dataIndex="address" title="ที่อยู่" />
        <Table.Column dataIndex="detail" title="คำอธิบายสั้นๆ" />
        <Table.Column
          dataIndex="created_at"
          title="วันที่สร้าง"
          render={(text) => <DateField value={text} />}
        />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
