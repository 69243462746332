import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
  CreateButton,
} from "@refinedev/antd";
import { Table, Space, Avatar } from "antd";
import { API_URL } from "config/axios";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List headerButtons={() => <CreateButton>เพิ่มแอดมินจากไอดี</CreateButton>}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["profile", "asset"]}
          title="ชื่อ-สกุล"
          render={(url) => <Avatar src={API_URL + url} />}
        />
        <Table.Column dataIndex="fullname" title="ชื่อ-สกุล" />
        <Table.Column dataIndex="role" title="ตำแหน่งงาน" />
        <Table.Column dataIndex="email" title="อีเมล์" />
        <Table.Column dataIndex="phone" title="เบอร์โทร" />
        <Table.Column dataIndex="username" title="username" />
        <Table.Column
          dataIndex={["created_at"]}
          title="สร้างเมื่อ"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="actions"
          render={(_, record: BaseRecord) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  disabled={record.role === "แอดมิน"}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
