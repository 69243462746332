import React from "react";
import { useNavigation } from "@refinedev/core";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  titleStyles,
} from "./styles";

import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  CardProps,
  LayoutProps,
  Divider,
  FormProps,
  theme,
} from "antd";

export const ExitingOnboarding: React.FC = () => {
  const { push } = useNavigation();
  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <Card
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
              ...containerStyles,
            }}
          >
            <div
              style={{
                textAlign: "center",
                padding: "3rem 0",
                fontSize: 20,
              }}
            >
              กรุณนำ <br />
              <br />
              <span style={{ fontWeight: "900", color: "#fa4155" }}>
                User ID = {localStorage.getItem("user_id")}
              </span>
              <br />
              <br />
              นี้ไปลงทะเบียนกับแอดมินในองค์กรของท่าน <br />
              หากยังไม่มีกดสมัครองค์กรใหม่
            </div>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              block
              style={{ marginTop: 15 }}
              onClick={() => {
                push("/onboarding");
              }}
            >
              ยกเลิก
            </Button>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
