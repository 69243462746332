import React, { useState } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useResource,
  useCustomMutation,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
  ImageField,
} from "@refinedev/antd";
import { Table, Space, Tag, Switch } from "antd";
import { API_URL } from "config/axios";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import SelfRegisterButton from "components/SelfRegisterButton";

export const CustomerList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable({
    syncWithLocation: true,
  });

  const { mutate } = useCustomMutation();
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);

  const status = [
    {
      label: "รอดำเนินการ",
      value: "pending",
      type: "processing",
      icon: <SyncOutlined spin />,
    },
    {
      label: "ไม่ตอบรับ",
      value: "cancelled",
      type: "error",
      icon: <CloseCircleOutlined />,
    },
    {
      label: "ถูกปิดการใช้งาน",
      value: "banned",
      type: "error",
      icon: <MinusCircleOutlined />,
    },
    {
      label: "ใช้งานอยู่",
      value: "approved",
      type: "success",
      icon: <CheckCircleOutlined />,
    },
  ];

  const updateStatus = (value: any, id: any) => {
    setUpdateStatusLoading(true);
    mutate(
      {
        url: `/customers/update_status/${id}`,
        method: "put",
        values: {
          status: value ? "approved" : "banned",
        },
      },
      {
        onError: (error, variables, context) => {
          setUpdateStatusLoading(false);
        },
        onSuccess: (data, variables, context) => {
          setUpdateStatusLoading(false);
          refetch();
        },
      }
    );
  };

  return (
    <List
      headerButtons={<SelfRegisterButton to="https://aowtani.com/sign-up" />}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["profile", "asset"]}
          title="รูปภาพ"
          render={(path) => (
            <ImageField
              width={50}
              height={50}
              value={API_URL + path}
              style={{ borderRadius: "50%" }}
              fallback="/user-fallback.png"
            />
          )}
        />
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column
          dataIndex="id"
          title="ชื่อ-สกุล"
          render={(_, data: any) =>
            `${data?.name_title} ${data?.first_name} ${data?.last_name}`
          }
        />
        <Table.Column dataIndex="phone" title="เบอร์โทร" />
        <Table.Column dataIndex="username" title="Username" />
        <Table.Column
          dataIndex="status"
          title="สถานะบัญชี"
          render={(value) => {
            const find = status.find((i) => i.value === value);
            return (
              <Tag icon={find?.icon} color={find?.type}>
                {find?.label}
              </Tag>
            );
          }}
        />
        <Table.Column
          title="จัดการบัญชี"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Switch
              checkedChildren="แบน"
              unCheckedChildren="เปิด"
              onChange={(val) => updateStatus(val, record.id)}
              loading={updateStatusLoading}
              checked={record?.status === "approved"}
            />
          )}
        />
        <Table.Column
          dataIndex={["created_at"]}
          title="สร้างเมื่อ"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
