import React from "react";
import { useNavigation } from "@refinedev/core";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  titleStyles,
} from "./styles";

import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  CardProps,
  LayoutProps,
  Divider,
  FormProps,
  theme,
} from "antd";
import axiosInstance from "config/axios";
import UploadOne from "components/UploadOne";

const { TextArea } = Input;

export interface IOrgRegisterForm {
  name: string;
  address: string;
  detail: string;
  president_name: string;
  website: string;
  phone: string;
  logo: any;
  banner: any;
}

const handleRegisterOrg = async (values: any) => {
  let payload = {
    organization: {
      ...values,
    },
  };

  const response = await axiosInstance.post("/organizations", payload);
  if (response.status === 201) {
    localStorage.setItem("org_id", response.data.id);
    window.location.replace("/");
  }
};

export const RegisterOnboarding: React.FC = () => {
  const [form] = Form.useForm<IOrgRegisterForm>();
  const { push } = useNavigation();
  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={24}>
          <Card
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
              ...containerStyles,
            }}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={handleRegisterOrg}
              validateMessages={{ required: "กรุณากรอก${label}" }}
            >
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="ชื่อองค์กร"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="president_name"
                    label="ผู้บริหารองค์กร"
                    rules={[{ required: false }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="address"
                    label="ที่อยู่องค์กร"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="detail"
                    label="รายละเอียดองค์กร"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="website" label="เว็บไซต์องค์กร">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    label="เบอร์โทรองค์กร"
                    rules={[{ required: false }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <UploadOne label="โลโก้" name="logo" />
                </Col>
                <Col span={20}>
                  <UploadOne label="แบนเนอร์" name="banner" />
                </Col>
                <Col span={12}>
                  <Button type="primary" size="large" htmlType="submit" block>
                    บันทึก
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => {
                      push("/onboarding");
                    }}
                    danger
                  >
                    ยกเลิก
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
