import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  ErrorComponent,
  notificationProvider,
  ThemedLayoutV2,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";

import {
  FlagOutlined,
  ApartmentOutlined,
  UngroupOutlined,
  InfoCircleOutlined,
  UserOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import {
  CommunityList,
  CommunityCreate,
  CommunityEdit,
  CommunityShow,
} from "./pages/communities";

import { OrganizationsEdit, OrganizationsShow } from "./pages/organizations";
import { UserList, UserCreate, UserEdit, UserShow } from "./pages/users";

import {
  DestinationToVisitList,
  DestinationToVisitCreate,
  DestinationToVisitEdit,
  DestinationToVisitShow,
} from "./pages/visits";

import { ReviewList } from "./pages/review";

import {
  OnBoarding,
  RegisterOnboarding,
  ExitingOnboarding,
} from "pages/onboarding";

import { PartnerList, PartnerShow } from "pages/partners";
import { LocalGuideList, LocalGuideShow } from "pages/guides";

import axiosInstance, { API_URL } from "./config/axios";
import { PackageList, ShowPackage } from "pages/partners/tour/package";
import { BookingList, ShowBooking } from "pages/partners/tour/booking";
import { ListProduct } from "pages/partners/shop/products";
import { ListOrder, ShowOrder } from "pages/partners/shop/orders";
import { ShowProduct } from "pages/partners/shop/products/show";
import { EventsCreate, EventsEdit, EventsList, EventsShow } from "pages/events";
import { DashboardPage } from "pages/dashboard";
import AppIcon from "components/AppIcon";
import { ThemedSiderV2 } from "components/layout/sider";
import { ThemedTitleV2 } from "components/layout/title";
import { PartnerEdit } from "pages/partners/edit";
import { LocalGuideEdit } from "pages/guides/edit";
import { CustomerList, CustomerShow } from "pages/customers";

const ORG_ID = localStorage.getItem("org_id") || null;

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={dataProvider(API_URL, axiosInstance)}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "แดชบอร์ด",
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: `${ORG_ID}/destination_visits`,
                list: "/destination_visits",
                create: "/destination_visits/create",
                edit: "/destination_visits/edit/:id",
                show: "/destination_visits/show/:id",
                meta: {
                  label: "สถานที่ท่องเที่ยว",
                  canDelete: true,
                  icon: <DeploymentUnitOutlined />,
                },
              },
              {
                name: `${ORG_ID}/communities`,
                list: `/communities`,
                create: `/communities/create`,
                edit: `/communities/edit/:id`,
                show: `/communities/show/:id`,
                meta: {
                  label: "ชุมชน",
                  canDelete: true,
                  icon: <UngroupOutlined />,
                },
              },
              {
                name: `business_partners`,
                meta: {
                  label: "ผู้ประกอบการ",
                  icon: <ApartmentOutlined />,
                },
              },
              {
                name: `TourActivity`,
                list: `/business_partners/tour`,
                show: `/business_partners/tour/show/:id`,
                edit: `/business_partners/tour/edit/:id`,
                meta: {
                  label: "ทัวร์",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Restaurant`,
                list: `/business_partners/restaurant`,
                show: `/business_partners/restaurant/show/:id`,
                edit: `/business_partners/restaurant/edit/:id`,
                meta: {
                  label: "ร้านอาหาร",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Hotel`,
                list: `/business_partners/hotel`,
                show: `/business_partners/hotel/show/:id`,
                edit: `/business_partners/hotel/edit/:id`,
                meta: {
                  label: "โรงแรม",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Shop`,
                list: `/business_partners/shop`,
                show: `/business_partners/shop/show/:id`,
                edit: `/business_partners/shop/edit/:id`,
                meta: {
                  label: "ร้านค้า",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Other`,
                list: `/business_partners/other`,
                show: `/business_partners/other/show/:id`,
                edit: `/business_partners/other/edit/:id`,
                meta: {
                  label: "อื่นๆ",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `local_guides`,
                list: `/local_guides`,
                show: `/local_guides/show/:id`,
                edit: `/local_guides/edit/:id`,
                meta: {
                  label: "ไกด์ท้องถิ่น",
                  icon: <FlagOutlined />,
                },
              },
              {
                name: "customers",
                list: "/customers",
                show: "/customers/show/:id",
                edit: "/customers/edit/:id",
                meta: {
                  label: "นักท่องเที่ยว",
                  icon: <TeamOutlined />,
                },
              },
              {
                name: "tani_events",
                list: "/events",
                create: "/events/create",
                edit: "/events/edit/:id",
                show: "/events/show/:id",
                meta: {
                  label: "กิจกรรม",
                },
              },
              {
                name: "organization",
                meta: {
                  label: "บัญชี",
                  icon: <ApartmentOutlined />,
                },
              },
              {
                name: `organizations`,
                list: `organizations/${ORG_ID}`,
                edit: "/organizations/edit/:id",
                meta: {
                  parent: "organization",
                  label: "หน่วยงาน",
                  icon: <InfoCircleOutlined />,
                },
              },
              {
                name: `${ORG_ID}/users`,
                list: `/users`,
                create: "/users/create",
                edit: "/users/edit/:id",
                show: "/users/show/:id",
                meta: {
                  parent: "organization",
                  label: "แอดมิน",
                  icon: <UserOutlined />,
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  ORG_ID !== "null" ? (
                    <Navigate to="/" />
                  ) : (
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <Outlet />
                    </Authenticated>
                  )
                }
              >
                <Route path="/onboarding" element={<OnBoarding />} />
                <Route
                  path="/onboarding/register"
                  element={<RegisterOnboarding />}
                />
                <Route
                  path="/onboarding/existing"
                  element={<ExitingOnboarding />}
                />
              </Route>
              <Route
                element={
                  ORG_ID == "null" ? (
                    <Navigate to="/onboarding" />
                  ) : (
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <ThemedLayoutV2
                        initialSiderCollapsed={false}
                        Header={() => <Header sticky />}
                        Sider={() => (
                          <ThemedSiderV2
                            Title={({ collapsed }) => (
                              <ThemedTitleV2
                                id={ORG_ID}
                                collapsed={collapsed}
                              />
                            )}
                            fixed
                          />
                        )}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  )
                }
              >
                <Route path="/" element={<DashboardPage />} />

                <Route path="/communities">
                  <Route index element={<CommunityList />} />
                  <Route path="create" element={<CommunityCreate />} />
                  <Route path="edit/:id" element={<CommunityEdit />} />
                  <Route path="show/:id" element={<CommunityShow />} />
                </Route>

                <Route path="/destination_visits">
                  <Route index element={<DestinationToVisitList />} />
                  <Route path="create" element={<DestinationToVisitCreate />} />
                  <Route path="edit/:id" element={<DestinationToVisitEdit />} />
                  <Route path="show/:id" element={<DestinationToVisitShow />} />
                  <Route path="reviews/:id" element={<ReviewList />} />
                </Route>

                <Route path="/business_partners/tour">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                  <Route path="packages/:id" element={<PackageList />} />
                  <Route
                    path="packages/:tour_id/show/:id"
                    element={<ShowPackage />}
                  />
                  <Route path="booking/:id" element={<BookingList />} />
                  <Route
                    path="booking/:tour_id/show/:id"
                    element={<ShowBooking />}
                  />
                </Route>

                <Route path="/business_partners/restaurant">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                </Route>

                <Route path="/business_partners/hotel">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                </Route>

                <Route path="/business_partners/shop">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="products/:id" element={<ListProduct />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                  <Route
                    path="products/:shop_id/show/:id"
                    element={<ShowProduct />}
                  />
                  <Route path="orders/:id" element={<ListOrder />} />
                  <Route
                    path="orders/:shop_id/show/:id"
                    element={<ShowOrder />}
                  />
                </Route>
                <Route path="/business_partners/other">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                </Route>

                <Route path="/local_guides">
                  <Route index element={<LocalGuideList />} />
                  <Route path="show/:id" element={<LocalGuideShow />} />
                  <Route path="edit/:id" element={<LocalGuideEdit />} />
                </Route>

                <Route path="/customers">
                  <Route index element={<CustomerList />} />
                  <Route path="show/:id" element={<CustomerShow />} />
                </Route>

                <Route path="/events">
                  <Route index element={<EventsList />} />
                  <Route path="create" element={<EventsCreate />} />
                  <Route path="edit/:id" element={<EventsEdit />} />
                  <Route path="show/:id" element={<EventsShow />} />
                </Route>

                <Route path="/organizations">
                  <Route path=":id" element={<OrganizationsShow />} />
                  <Route path="edit/:id" element={<OrganizationsEdit />} />
                </Route>

                <Route path="/users">
                  <Route index element={<UserList />} />
                  <Route path="create" element={<UserCreate />} />
                  <Route path="edit/:id" element={<UserEdit />} />
                  <Route path="show/:id" element={<UserShow />} />
                </Route>

                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
            <DocumentTitleHandler
              handler={() => "ระบบจัดการข้อมูลสำหรับองค์กร"}
            />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;

// update git
