import React from "react";
import { Row, Col, Typography } from "antd";
import { DashboardTotalCountCard } from "./components/CountWidget";
import { PartnerCostCard } from "./components/PartnerCost";
import CustomerTotalCard from "./components/Customer";
import Poppular from "./components/poppular";

export const DashboardPage: React.FC = () => {
  return (
    <>
      <Row gutter={[24, 32]}>
        <Col span={6}>
          <DashboardTotalCountCard
            label="จำนวนสถานที่ท่องเที่ยว"
            resource="destination_visit"
          />
        </Col>
        <Col span={6}>
          <DashboardTotalCountCard label="จำนวนชุมชน" resource="community" />
        </Col>
        <Col span={6}>
          <DashboardTotalCountCard
            label="จำนวนผู้ประกอบการ"
            resource="business_partner"
          />
        </Col>
        <Col span={6}>
          <DashboardTotalCountCard
            label="จำนวนไกด์ท้องถิ่น"
            resource="local_guide"
          />
        </Col>
        <Col span={12}>
          <PartnerCostCard />
        </Col>
        <Col span={12}>
          <Poppular />
        </Col>
        <Col span={24}>
          <CustomerTotalCard />
        </Col>
      </Row>
    </>
  );
};
