import React from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
} from "@refinedev/antd";
import { Typography } from "antd";
import ReviewPage from "components/ReviewPage";
import ImageList from "components/ImageList";

const { Title } = Typography;

export const DestinationToVisitShow: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <>
      <Show isLoading={isLoading} title="แสดงสถานที่ท่องเที่ยว">
        <Title level={5}>ชื่อสถานที่</Title>
        <TextField value={record?.name} />
        <Title level={5}>ที่อยู่</Title>
        <TextField value={record?.address} />
        <Title level={5}>คำอธิบาย</Title>
        <TextField value={record?.description} />
        <Title level={5}>ประเภท</Title>
        <TextField value={record?.destination_type.name} />
        <Title level={5}>รูปภาพ</Title>
        <ImageList images={record?.banners} />
        <Title level={5}>แผนที่</Title>
        <div
          style={{
            borderRadius: 6,
            width: 600,
            height: 400,
            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={{ __html: record?.embed_map }}
        />
        <br />
        <Title level={5}>คอนเทนต์</Title>
        <div
          style={{
            border: "0.1px solid rgba(0,0,0,0.45)",
            padding: 15,
            borderRadius: 6,
          }}
          dangerouslySetInnerHTML={{ __html: record?.content }}
        />
      </Show>
      <ReviewPage resource="destination_visits" />
    </>
  );
};
