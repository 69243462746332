import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useNavigation,
  useList,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";
import { MessageOutlined } from "@ant-design/icons";

export const DestinationToVisitList: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { push } = useNavigation();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="name" title="สถานที่" />
        <Table.Column dataIndex={["destination_type", "name"]} title="ประเภท" />
        <Table.Column dataIndex="description" title="คำอธิบายสั้นๆ" />
        <Table.Column dataIndex="address" title="ที่อยู่" />
        <Table.Column
          dataIndex={["created_at"]}
          title="วันที่สร้าง"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
