import React, { useState } from "react";

import {
  Card,
  Row,
  Col,
  Space,
  Avatar,
  Rate,
  Image,
  Button,
  Input,
} from "antd";

import UploadMultipleStandAlone from "components/UploadMultipleStandAlone";

import { useParsed, useList, useCustomMutation } from "@refinedev/core";
import { ReplyReviewProps, ReviewProps } from "types";
import { API_URL } from "config/axios";

const ReplyList = (reply: ReplyReviewProps) => (
  <>
    <div
      style={{
        color: "rgba(0, 0, 0, 0.45)",
        fontStyle: "italic",
        display: "flex",
        flexDirection: "column",
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 30,
      }}
    >
      <span>{reply.text}</span>
      {reply.images && (
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          <Space>
            {reply.images.map((img) => (
              <div
                style={{
                  width: 80,
                  objectFit: "contain",
                  objectPosition: "center",
                }}
              >
                <Image src={API_URL + img.asset} />
              </div>
            ))}
          </Space>
        </Image.PreviewGroup>
      )}
    </div>
  </>
);

export const ReviewList: React.FC = () => {
  const [reply, setReply] = useState<number | null>(null);
  const [replyText, setReplyText] = useState("");
  const [replyImages, setReplyImages] = useState([]);

  const { id } = useParsed();

  const { data } = useList<ReviewProps>({
    resource: `/destination_visits/${id}/reviews`,
  });

  const { mutate } = useCustomMutation();

  const handleReply = (id: number) => {
    const user_id = localStorage.getItem("user_id") || "";
    const payload = {
      reply_review: {
        text: replyText,
        images: replyImages,
        user_id: parseInt(user_id),
      },
    };

    mutate(
      {
        url: `/${id}/reply_reviews`,
        method: "post",
        values: payload,
      },
      {
        onSuccess: (data, variables, context) => {
          // Let's celebrate!
          setReply(null);
        },
      }
    );
  };

  return (
    <Card title="ความคิดเห็น">
      {data?.data.length === 0 && "ยังไม่มีรีวิว"}
      {data?.data.map((item) => (
        <Row style={{ paddingBottom: 16 }}>
          <Col span={1}>
            <Avatar
              style={{ marginTop: 8 }}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
          </Col>
          <Col span={22}>
            <Space direction="vertical" style={{ gap: 0 }}>
              <Space>
                <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  {`${item.customer.first_name} ${item.customer.last_name}`}
                </span>
                <span>{item.created_at}</span>
              </Space>
              <Rate disabled defaultValue={item.star} />
              <p>{item.text}</p>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) =>
                    console.log(
                      `current index: ${current}, prev index: ${prev}`
                    ),
                }}
              >
                <Space>
                  {item.images.map((img) => (
                    <Image width={80} src={API_URL + img.asset} />
                  ))}
                </Space>
              </Image.PreviewGroup>
            </Space>
            {item.reply.map((reply) => (
              <ReplyList {...reply} />
            ))}
            {item.id === reply ? (
              <div style={{ paddingTop: 16 }}>
                <Space direction="vertical">
                  <Input
                    width={300}
                    placeholder="เพิ่มความคิดเห็น"
                    onChange={(e) => setReplyText(e.target.value)}
                  />
                  <UploadMultipleStandAlone
                    name="images"
                    label=""
                    onChange={(e) => {
                      const res = e.map((item: any) => item.response);
                      if (res.length > 0) {
                        setReplyImages(res);
                      }
                    }}
                  />
                  <Button
                    style={{ position: "relative", top: -10 }}
                    type="primary"
                    onClick={() => handleReply(item.id)}
                  >
                    บันทึก
                  </Button>
                </Space>
              </div>
            ) : (
              <>
                <div
                  style={{
                    color: "rgba(0, 0, 0, 0.45)",
                    fontStyle: "italic",
                    paddingTop: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => setReply(item.id)}
                >
                  <span>ตอบกลับ...</span>
                </div>
              </>
            )}
          </Col>
          <Col span={1}>
            <Button type="link">{item.is_shown ? "เลิกซ่อน" : "ซ่อน"}</Button>
          </Col>
        </Row>
      ))}
    </Card>
  );
};
