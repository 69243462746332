import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useCustomMutation,
  useParsed,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Show, NumberField, TextField, ImageField } from "@refinedev/antd";
import { Switch, Typography } from "antd";
import { API_URL } from "config/axios";

const { Title } = Typography;

export const CustomerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="แสดงนักท่องเที่ยว" headerButtons={<></>}>
      <Title level={5}>คำนำหน้า</Title>
      <ImageField
        value={API_URL + record?.profile?.asset}
        width={150}
        height={150}
        fallback="/user-fallback.png"
        style={{ borderRadius: "100%", objectFit: "cover" }}
      />
      <br />
      <br />
      <Title level={5}>คำนำหน้า</Title>
      <TextField value={record?.name_title} />
      <Title level={5}>ชื่อ</Title>
      <TextField value={record?.first_name} />
      <Title level={5}>นามสกุล</Title>
      <TextField value={record?.last_name} />
      <Title level={5}>เบอร์โทรศัพท์</Title>
      <TextField value={record?.phone ?? ""} />
      <Title level={5}>ที่อยู่</Title>
      <TextField value={record?.address} />
      <Title level={5}>รหัสไปรษณีย์</Title>
      <TextField value={record?.post_code ?? ""} />
    </Show>
  );
};
