import React, { useState, useEffect } from "react";
import { useList } from "@refinedev/core";
import { Card } from "antd";
import { Pie } from "@ant-design/charts";
import { SelectDate } from "./SelectDate";

const ORG_ID = localStorage.getItem("org_id");

export const PartnerCostCard: React.FC = () => {
  const [dateString, setDateString] = useState("");

  const typeString = [
    {
      key: "shop",
      value: "ร้านค้า",
    },
    {
      key: "tour_activity",
      value: "กิจกรรมทัวร์",
    },
    {
      key: "hotel",
      value: "ที่พัก",
    },
    {
      key: "restaurant",
      value: "ร้านอาหาร",
    },
  ];

  const { data } = useList({
    resource: `/organizations/${ORG_ID}/organization_analytics/partners_income?${dateString}`,
    queryOptions: {
      select: (data: any) => {
        const formattedData = Object.entries(data.data)
          .map(([type, value]) => ({
            type: typeString.find((item) => item.key === type)?.value,
            value,
          }))
          .filter((item) => item.type);
        data = formattedData;
        return data ?? data;
      },
    },
  });

  const config = {
    data: data as any,
    angleField: "value",
    colorField: "type",
    paddingRight: 80,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };

  return (
    <Card bordered={false} title="รายได้ผู้ประกอบการแยกตามประเภท">
      <SelectDate value={dateString} onChange={setDateString} />
      {data ? (
        <div style={{ width: 400, margin: "auto" }}>
          <Pie {...config} />
        </div>
      ) : (
        "กำลังโหลดกราฟ"
      )}
      <br />
    </Card>
  );
};
